import { Box, Flex, Text } from 'mds-web-ui'
import { MDXProvider } from '@mdx-js/react'
import { Link as GatsbyLink } from 'gatsby'
import React, { useRef } from 'react'
import { Heading } from '../../utils/Typography'

import LanguageSelector from '../../WhatsNew/LanguageSelector'
import { SkipNavContent } from '../SkipLink'
import { ActionsIcon, FlowIcon, TimepageIcon } from './Icon'
import Review from './Review'
import { Screenshot } from './Screenshot'
import TOC from './TOC'
import { Tab, Tabs } from './Tabs'
import Vimeo from './Vimeo'
import './style.css'
import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { space, layout, color, grid, position, flexbox } from 'styled-system'

export const Grid = styled('div')
  .attrs(({ placeItems, ...props }) => ({
    placeItems: placeItems || 'center',
    ...props
  }))
  .withConfig({
    shouldForwardProp
  })(
  {
    display: 'grid'
  },
  props => props.css,
  space,
  layout,
  color,
  grid,
  position,
  flexbox // We have placeItems, but this is more for alignItems and justifyContent
)

const SectionTitle = props => (
  <Text
    className="section"
    color="#9B9B9B"
    fontSize={10}
    css={{ textTransform: 'uppercase' }}
    mb={[2, 3]}
    {...props}
  />
)

const Title = props => (
  <Heading
    as="h1"
    fontWeight="semibold"
    fontSize={[4, 28]}
    color="grayapptitle"
    fontFamily="sans"
    animate={false}
    {...props}
  />
)

const Link = ({ href, ...rest }) => {
  if (href.startsWith('/support/')) {
    return <GatsbyLink to={href} {...rest} />
  }

  return <a href={href} {...rest} />
}

const components = {
  Vimeo,
  TimepageIcon,
  ActionsIcon,
  FlowIcon,
  Tabs,
  Tab,
  Review,
  a: Link,
  Screenshot
}

const DocsContent = ({
  title,
  children,
  section,
  slug,
  baseURL,
  tableOfContents
}) => {
  // Can pass in body (from page query) or children (pre-rendered)
  const ref = useRef(null)
  console.log(children)
  // useAutoplayVideos(ref)
  return (
    <>
      <SkipNavContent />

      <Grid
        as="article"
        ref={ref}
        gridTemplateColumns={['1fr', '1fr auto']}
        gridTemplateRows="repeat(3, auto)"
        gridTemplateAreas={[
          `"slug"
           "title"
           "toc"
           "content"`,
          `"slug slug"
           "title title"
           "content toc"`
        ]}
        gridColumnGap={[0, 4]}
        css={{
          '& .iphone .gatsby-resp-image-wrapper': {
            marginTop: '1.5em',
            marginBottom: '1.5em'
          },
          '& .iphone.portrait .gatsby-resp-image-wrapper': {
            // 300*650px image, 31px target border radius
            borderRadius: `${(31 / 300) * 100}%/${(31 / 650) * 100}% !important`
          },
          '& .iphone.landscape .gatsby-resp-image-wrapper': {
            borderRadius: `${(31 / 650) * 100}%/${(31 / 300) * 100}% !important`
          },
          '& .no-border .gatsby-resp-image-wrapper': {
            borderRadius: 0
          },
          '& .side-by-side': {
            display: 'flex',
            '& > span': {
              flex: 1
            },
            'span + span': {
              marginLeft: '8px !important'
            },
            '& > div': {
              flex: 1
            },
            'div + div': {
              marginLeft: '8px !important'
            }
          },
          '& .center': {
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        }}
      >
        <Flex
          justifyContent="space-between"
          css={{
            gridArea: 'slug'
          }}
        >
          <SectionTitle>{section}</SectionTitle>
          {slug && <LanguageSelector baseURL={baseURL} slug={slug} />}
        </Flex>

        <Title
          css={{
            gridArea: 'title'
          }}
        >
          {title}
        </Title>

        <TOC
          tableOfContents={tableOfContents}
          css={{
            gridArea: 'toc',
            width: 225
          }}
        />

        <Box
          css={{
            gridArea: 'content'
          }}
          pb="15dvh"
        >
          <MDXProvider components={components}>{children}</MDXProvider>
        </Box>
      </Grid>
    </>
  )
}

export default DocsContent
