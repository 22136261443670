import { Box, Hide } from 'mds-web-ui'
import React, { useEffect, useRef, useCallback, useLayoutEffect } from 'react'
import { space, layout, typography, color, flexbox } from 'styled-system'

import styled from 'styled-components'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { position } from 'styled-system'
import { useScroll, useMotionValueEvent } from 'framer-motion'
import { ChevronUp } from 'react-feather'
import { Heading } from '../../utils/Typography'
import { cn } from '../../shared/cn'

const StyledNavLink = styled(Box).withConfig({ shouldForwardProp })({
  fontSize: '14px',
  color: 'rgb(0 0 0)',
  opacity: 0.4,
  textWrap: 'pretty',
  position: 'relative',
  transition: 'color 150ms ease-out',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 1,

    background:
      'linear-gradient(180deg, rgb(0 0 0) 0%, rgb(0 0 0) 0%, rgb(0 0 0 / 0.1) 0%)'
  },

  // Apply styles to elements that appear before the active element
  '&:not(.toc--active):not(.toc--active ~ &)': {
    opacity: 0.75,

    '&::before': {
      background:
        'linear-gradient(180deg, rgb(0 0 0 / 0.3) 0%, rgb(0 0 0 / 0.3) 100%, rgb(0 0 0 / 0.1) 100%)'
    }
  },

  '&.toc--active': {
    opacity: 1,

    '&::before': {
      background:
        'linear-gradient(180deg, rgb(0 0 0) 0%, rgb(0 0 0) 100%, rgb(0 0 0 / 0.1) 100%)'
    }
  }
})

const NavLink = ({ children, className, ...props }) => {
  return (
    <StyledNavLink
      as="a"
      py={1}
      pl={3}
      className={cn('toc anchor', className)}
      {...props}
    >
      {children}
    </StyledNavLink>
  )
}

const TOCNav = styled('nav').withConfig({ shouldForwardProp })(
  {
    alignSelf: 'start',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  props => props.css,
  space,
  layout,
  typography,
  color,
  flexbox,
  position
)

const Container = styled('nav').withConfig({ shouldForwardProp })(
  {
    alignSelf: 'start',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 8,
    top: '2rem',
    maxHeight: 'calc(100vh - 4rem)',
    overflowY: 'auto',
    position: 'relative'
  },
  props => props.css,
  space,
  layout,
  typography,
  color,
  flexbox,
  position
)

const VISIBLE_CLASSNAME = 'toc__scrolltop--visible'

const ScrollTopButton = styled('button').withConfig({ shouldForwardProp })(
  {
    unset: 'all',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    borderRadius: '5px',

    color: '#2E3135',
    backgroundColor: '#EDEEF0',
    cursor: 'pointer',

    fontSize: 14,
    fontWeight: 400,
    textDecoration: 'none',

    border: 0,
    borderRadius: 8,

    transition: 'all 80ms ease-out',

    opacity: 0,
    visibility: 'hidden',

    [`&.${VISIBLE_CLASSNAME}`]: {
      opacity: 1,
      visibility: 'visible'
    },

    '&:hover': {
      backgroundColor: '#B0B4BA'
    },

    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.5
    }
  },
  space,
  layout,
  color,
  flexbox,
  position
)

const TOCScrollTop = ({ ...props }) => {
  const mainRef = useRef(null)
  const buttonRef = useRef(null)

  const onClick = useCallback(() => {
    const main = document.querySelector('main')
    if (!main) {
      return
    }

    main.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const { scrollY } = useScroll({
    container: mainRef,
    layoutEffect: false
  })

  useMotionValueEvent(scrollY, 'change', c => {
    if (c < 200) {
      buttonRef.current.classList.remove(VISIBLE_CLASSNAME)
    } else {
      buttonRef.current.classList.add(VISIBLE_CLASSNAME)
    }
  })

  useLayoutEffect(() => {
    if (mainRef.current) {
      return
    }

    mainRef.current = document.querySelector('main')

    return () => {
      mainRef.current = null
    }
  }, [])

  return (
    <ScrollTopButton
      onClick={onClick}
      type="button"
      pr={3}
      pl={2}
      py={2}
      ref={buttonRef}
      {...props}
    >
      <ChevronUp color="#2E3135" size={16} />
      <span>Back to top</span>
    </ScrollTopButton>
  )
}

const TOC = ({ tableOfContents, css, ...props }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    const allLinks = Array.from(document.querySelectorAll('#tocnav a'))
    const allSections = Array.from(document.querySelectorAll('article h2'))

    const mainRect = document.querySelector('main').getBoundingClientRect()
    const elementInViewport = el => {
      var rect = el.getBoundingClientRect()

      return (
        rect.top >= -1 &&
        rect.left >= 0 &&
        rect.bottom <= mainRect.height &&
        rect.right <= mainRect.width
      )
    }

    // Set up an intersection observer, so that when the user scrolls past a heading in the TOC, we can highlight it
    const observer = new IntersectionObserver(entries => {
      const allEntries = new Set(
        entries.filter(entry => entry.isIntersecting).map(entry => entry.target)
      )

      let currentSection
      for (let i = 0; i < allSections.length; i++) {
        currentSection = allSections[i]
        if (
          elementInViewport(currentSection) ||
          allEntries.has(currentSection)
        ) {
          allLinks.forEach(link => link.classList.remove('toc--active'))
          const activeLink = document.querySelector(
            `#tocnav a[href$="#${currentSection.id}"]`
          )

          if (!activeLink) {
            continue
          }

          activeLink.classList.add('toc--active')
          break
        }
      }
    })

    // Track all headings in the TOC
    allSections.forEach(heading => {
      observer.observe(heading)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Container
      as="aside"
      position={['static', 'sticky']}
      css={{
        ...css
      }}
      {...props}
    >
      <Heading as="h4" fontSize={1} m={0} fontWeight={600} animate={false}>
        On this page
      </Heading>

      <TOCNav id="tocnav" py={1} ref={containerRef}>
        {tableOfContents.items
          .filter(item => item.title)
          .map((item, i) => {
            return (
              <NavLink
                href={item.url}
                key={item.url}
                className={i === 0 ? 'toc--active' : ''}
              >
                {item.title}
              </NavLink>
            )
          })}
      </TOCNav>

      <Hide xs s>
        <TOCScrollTop mt={3} />
      </Hide>
    </Container>
  )
}

const shouldShowTOC = toc =>
  toc &&
  toc.items &&
  toc.items.filter(({ title }) => title && title.length).length > 1

const TOCContainer = props => {
  const { tableOfContents } = props
  if (!shouldShowTOC(tableOfContents)) {
    return null
  }

  return <TOC {...props} />
}

export default TOCContainer
